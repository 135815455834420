@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    -webkit-tap-highlight-color: transparent;
}

#dialog-box-overlay {
    margin: 0 !important;
}

@layer base {
    :root {
        --color-black: black;
        --color-white: white;
        --color-primary: #36dd93;
        --color-primary-light: #C0FFD9;
        --color-secondary: #0b1b29;
        --color-tertiary: #0b1b29;
        --color-primary-bg-text: #0B1B29;
        --color-secondary-bg-text: #36DD93;
        --color-tertiary-bg-text: #FFFFFF;
        --color-primary-text: #0b1b29;
        --color-primary-hover: #00c47d;
        --color-btn-secondary-bg-hover: #e0e8f0;
        --color-chip-primary: #36dd93;
        --color-chip-primary-text: #0b1b29;
        --color-chip-secondary: #d8e2ec;
        --color-chip-secondary-text: #0b1b29;
        --color-disabled-bg: #e1e3e5;
        --color-border-primary: #36dd93;
        --color-surface: #eff3f8;
        --color-surface-child: #d8e2ec;
        --color-surface-accent: #c0ffd9;
        --color-app-background: #FFFFFF;
        --color-status-primary: #36dd93;
        --color-status-primary-light: #C0FFD9;
        --color-status-primary-text: black;
        --color-status-secondary: black;
        --color-status-secondary-text: white;
        --color-status-undefined: #D8E2EC;
        --color-status-undefined-text: black;
    }

    [data-theme="blue"] {
        --color-primary: #0082b7;
        --color-secondary: #1B1B1B;
        --color-tertiary: #FFFFFF;
        --color-primary-bg-text: #FFFFFF;
        --color-secondary-bg-text: #FFFFFF;
        --color-tertiary-bg-text: #000000;
        --color-primary-text: #0b1b29;
        --color-primary-hover: #0E567B;
        --color-btn-secondary-bg-hover: #E2E1EC;
        --color-chip-primary: #0082b7;
        --color-chip-primary-text: #ffffff;
        --color-chip-secondary: #DADBE7;
        --color-chip-secondary-text: #000000;
        --color-disabled-bg: #e1e3e5;
        --color-border-primary: #0082b7;
        --color-surface: #F1EFF6;
        --color-surface-child: #F1EFF6;
        --color-surface-accent: #DDE1FF;
        --color-app-background: #FEFBFF;
    }

    [data-theme="red"] {
        --color-primary: #BA1A21;
        --color-secondary: #1B1B1B;
        --color-tertiary: #FFFFFF;
        --color-primary-bg-text: #FFFFFF;
        --color-secondary-bg-text: #FFFFFF;
        --color-tertiary-bg-text: #000000;
        --color-primary-text: #0b1b29;
        --color-primary-hover: #930011;
        --color-btn-secondary-bg-hover: #EADFE3;
        --color-chip-primary: #BA1A21;
        --color-chip-primary-text: #ffffff;
        --color-chip-secondary: #E4D8DB;
        --color-chip-secondary-text: #000000;
        --color-disabled-bg: #e1e3e5;
        --color-border-primary: #BA1A21;
        --color-surface: #F5EEF2;
        --color-surface-child: #F5EEF2;
        --color-surface-accent: #FFDAD6;
        --color-app-background: #fffbff;
    }

    [data-theme="purple"] {
        --color-primary: #812CD5;
        --color-secondary: #1B1B1B;
        --color-tertiary: #FFFFFF;
        --color-primary-bg-text: #FFFFFF;
        --color-secondary-bg-text: #FFFFFF;
        --color-tertiary-bg-text: #000000;
        --color-primary-text: #0b1b29;
        --color-primary-hover: #6600B6;
        --color-btn-secondary-bg-hover: #E8DFEC;
        --color-chip-primary: #812CD5;
        --color-chip-primary-text: #ffffff;
        --color-chip-secondary: #E1D8E6;
        --color-chip-secondary-text: #000000;
        --color-disabled-bg: #e1e3e5;
        --color-border-primary: #812CD5;
        --color-surface: #F4EEF6;
        --color-surface-child: #F4EEF6;
        --color-surface-accent: #EFDBFF;
        --color-app-background: #fffbff;
    }

    [data-theme="black"] {
        --color-primary: #1A1C1E;
        --color-secondary: #1B1B1B;
        --color-tertiary: #FFFFFF;
        --color-primary-bg-text: #FFFFFF;
        --color-secondary-bg-text: #FFFFFF;
        --color-tertiary-bg-text: #000000;
        --color-primary-text: #0b1b29;
        --color-primary-hover: #2F3033;
        --color-btn-secondary-bg-hover: #E4E3E6;
        --color-chip-primary: #1A1C1E;
        --color-chip-primary-text: #ffffff;
        --color-chip-secondary: #DDDCDF;
        --color-chip-secondary-text: #1A1C1E;
        --color-disabled-bg: #e1e3e5;
        --color-border-primary: #1A1C1E;
        --color-surface: #F1F0F3;
        --color-surface-child: #F1F0F3;
        --color-surface-accent: #E3E2E6;
        --color-app-background: #fdfcff;
    }
}

.MuiButtonBase-root.MuiPickersDay-root:not(.Mui-selected) {
    background-color: transparent;
}

.MuiButtonBase-root.MuiPickersDay-root:not(.Mui-selected):hover,
.MuiButtonBase-root.MuiPickersDay-root:not(.Mui-selected):focus {
    background-color: var(--color-surface);
}

.fc-today-button {
    background-color: var(--color-secondary) !important;
}

.fc-next-button, .fc-prev-button {
    background-color: #0082b7 !important;
    border: none !important;
}

.fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button {
    border: none !important;
    background-color: var(--color-secondary) !important;
}

.fc-button-active {
    background-color: var(--color-primary) !important;
    border: none !important;
}

.fc-day-today {
    background-color: var(--color-surface) !important;
}